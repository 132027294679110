<template>
  <div>
    <hero-bar :has-right-visible="false" v-if="surveyDetails">
      {{ surveyDetails.title }}
    </hero-bar>
    <hero-bar :has-right-visible="false" v-else>
        <b-skeleton size="is-large" width="350" :active="loading"/>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component title="Overview" icon="notebook-outline" class="tile is-child">
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Project
            </div>
            <div class="column">
              <span v-if="surveyDetails.organization">{{ surveyDetails.organization.name }}</span>
              <b-skeleton :width="r100()" :active="loading"/>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Status
            </div>
            <div class="column">
              <span v-if="surveyDetails">{{ surveyDetails.status }}</span>
              <b-skeleton :width="r100()" :active="loading"/>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Return Rate
            </div>
            <div class="column">
              <ReturnRateBar v-if="items !== null"
                :sessions-finished="numTotalSurveySessions.sessions_finished"
                :sessions-total="numTotalSurveySessions.sessions_total"/>
              <b-collapse
                class="card"
                :open="false">
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button">
                  <p class="card-header-title">
                    Return Rates by Division
                  </p>
                  <a class="card-header-icon">
                    <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content" v-if="items !== null">
                  <div class="content" v-for="divRate in items">
                    <p>{{ divRate.div_name }}</p>
                    <ReturnRateBar
                      :sessions-finished="divRate.sessions_finished"
                      :sessions-total="divRate.sessions_total"/>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Creator
            </div>
            <div class="column">
              <span v-if="surveyDetails.creator">{{ surveyDetails.creator.name }}</span>
              <b-skeleton :width="r100()" :active="loading"/>
            </div>
          </div>

          <div class="columns">
            <div class="column is-one-quarter has-text-weight-bold">
              Template
            </div>
            <div class="column">
              <span v-if="surveyDetails">{{ surveyDetails.title }}</span>
              <b-skeleton :width="r100()" :active="loading"/>
            </div>
          </div>
          <div class="columns" v-if="items !== null && numTotalSurveySessions.sessions_total">
            <div class="column is-one-quarter has-text-weight-bold">
              Number of session keys
            </div>
            <div class="column">
              {{ numTotalSurveySessions.sessions_total }}
            </div>
          </div>
        </card-component>

        <card-component title="Edit" icon="square-edit-outline" class="tile is-child">
          <b-field label="Starts" label-position="on-border" v-if="surveyDetails">
            <b-input expanded v-if="!fieldEditable['starts_at']"
                     disabled v-model="surveyDetails.starts_at"
                     icon="calendar" />
            <b-datetimepicker
              v-model="surveyStartsat"
              v-if="fieldEditable['starts_at']" inline expanded :timepicker="timepicker" />
            <p class="control">
              <button class="button is-primary" @click="toggleEdit('starts_at')">
                <b-icon :icon="fieldEditable['starts_at'] ? 'lock-open-variant-outline' : 'lock-outline'"/>
              </button>
            </p>
          </b-field>

          <b-field label="Expires" label-position="on-border" v-if="surveyDetails">
            <b-input expanded v-if="!fieldEditable['expires_at']"
                     disabled v-model="surveyDetails.expires_at"
                     icon="calendar" />
            <b-datetimepicker
              v-model="surveyExpiresat"
              v-if="fieldEditable['expires_at']" inline expanded :timepicker="timepicker" />
            <p class="control">
              <button class="button is-primary" @click="toggleEdit('expires_at')">
                <b-icon :icon="fieldEditable['expires_at'] ? 'lock-open-variant-outline' : 'lock-outline'"/>
              </button>
            </p>
          </b-field>

          <b-field label="Minimum Participants" label-position="on-border" v-if="surveyDetails">
            <b-input expanded :disabled="!fieldEditable['minimal_participants']" v-model="minimal_participants"
                     icon="account-group" />
            <p class="control">
              <button class="button is-primary" @click="toggleEdit('minimal_participants')">
                <b-icon :icon="fieldEditable['minimal_participants'] ? 'lock-open-variant-outline' : 'lock-outline'" />
              </button>
            </p>
          </b-field>

          <div class="columns is-vcentered" v-if="surveyDetails">
            <div class="column is-half">
              <b-checkbox v-model="shuffle_items" :disabled="!fieldEditable['shuffle_items']" expanded>
                Shuffle items
              </b-checkbox>
            </div>
            <div class="column is-half">
              <b-button type="is-primary"@click="toggleEdit('shuffle_items')" class="is-pulled-right">
                <b-icon size="is-small"
                        :icon="fieldEditable['shuffle_items'] ? 'lock-open-variant-outline' : 'lock-outline'"/>
              </b-button>
            </div>
          </div>

          <b-skeleton size="is-large" :active="loading"/>
          <b-skeleton size="is-large" :active="loading"/>
          <b-skeleton size="is-large" :active="loading"/>

          <div class="buttons" style="margin-top: 1.5rem;">
            <b-button icon-left="table" class="is-primary is-light" expanded @click="downloadCsv()">Download CSV...</b-button>
            <b-button disabled icon-left="pause" expanded>Pause survey...</b-button>
            <b-button disabled icon-left="stop" type="is-danger" expanded>Finish survey...</b-button>
            <b-button disabled icon-left="message" expanded>Message administrator</b-button>
          </div>

        </card-component>
      </tiles>

      <card-component title="Participants" class="has-table has-mobile-sort-spaced" icon="account-multiple">
        <div v-if="!loading && surveyDetails && surveyDetails.user.length > 0">
          <app-table
            :columns="columns"
            :page="page"
            :total="total"
            :data="surveyDetails.user"
            :editRoute="editRoute"
            :deleteRoute="endpoint"
            @onPageChange="onPageChange"
            :pagination-top="true"
            :pagination-bottom="true"
          />
        </div>
        <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>

      <card-component title="Description" icon="text" class="">
        <span v-if="item" v-html="surveyDetails.description"></span>
        <b-skeleton :active="loading"/>
        <b-skeleton :active="loading"/>
        <b-skeleton :width="r100()" :active="loading"/>
      </card-component>

      <card-component title="Intro" icon="text" class="">
        <span v-if="item" v-html="surveyDetails.intro"></span>
        <b-skeleton :active="loading"/>
        <b-skeleton :width="r100()" :active="loading"/>
      </card-component>

      <card-component title="Outro" icon="text" class="">
        <span v-if="item" v-html="surveyDetails.outro"></span>
        <b-skeleton :active="loading"/>
        <b-skeleton :active="loading"/>
        <b-skeleton :width="r100()" :active="loading"/>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import { mapActions, mapGetters } from 'vuex'
import { ApiSurveys, ApiStats, ApiUsers, ApiDownloadInstanceCsv } from '@/api-routes.js'
import CardComponent from '@/components/CardComponent'

import ReturnRateBar from '@/components/surveys/rate/ProgressBar'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'SurveyDetail',
  mixins: [ListTemplateMixin],
  components: {
    ReturnRateBar,
    CardComponent,
    TitleBar,
    HeroBar,
    Tiles,
    'app-table': Table
  },
  props: {
    slug: String
  },
  data () {
    return {
      fieldEditable: {
        'starts_at': false,
        'expires_at': false,
        'minimal_participants': false,
        'shuffle_items': false,
      },
      updateData: {},
      timepicker: {
        incrementMinutes: 15,
      },
      surveyDetails: {
        access_key: null,
        created_at: null,
        creator: {
          name: ""
        },
        deleted_at: null,
        description: null,
        division_id: null,
        expires_at: null,
        hash: null,
        id: null,
        intro: null,
        minimal_participants: null,
        organization: {
          name: ""
        },
        outro: null,
        public: null,
        sessions_finished: null,
        sessions_inprogress: null,
        sessions_pending: null,
        sessions_total: null,
        shuffle_items: null,
        slug: null,
        starts_at: null,
        status: null,
        survey_id: null,
        title: null,
        updated_at: null,
        user: [],
        user_id: null
      },
      // data for participant table:
      endpoint: ApiUsers,
      editRoute: 'users.edit',
      total: 0,
      page: 1,
      columns: {'name':'form', 'email':'form',}
    }
  },
  computed: {
    ...mapGetters([
      // 'surveyDetail', - use item
      // 'surveyDivReturnRates', - use items
      'error',
      'item',
      'loading',
      'items'
    ]),
    titleStack () {
      return [
        'Admin',
        'Survey Detail'
      ]
    },
    numTotalSurveySessions () {
      if(this.items !== null) {
        return this.items.reduce((acc, div) => {
          for (const status in div) {
            if (status !== 'div_name') {
              acc[status] = acc.hasOwnProperty(status) ? (acc[status] + div[status]) : div[status];
            }
          }
          return acc;
        }, {});
      }
    },

    // Form field getters and setters
    // updateData is used as a cache to store modified data until it can be sent to server and commited
    // to the store. When the edit button is toggled closed, whatever is in updateData is being sent to
    // the server.
    surveyStartsat: {
      get: function () {
        return this.surveyDetails.starts_at ? new Date(Date.parse(this.surveyDetails.starts_at)) : new Date() },
      set: function (modifiedDate) {
        this.updateData['starts_at'] = modifiedDate;
      }
    },
    surveyExpiresat: {
      get: function () {
        return this.surveyDetails.expires_at ? new Date(Date.parse(this.surveyDetails.expires_at)) : new Date() },
      set: function (modifiedDate) {
        this.updateData['expires_at'] = modifiedDate;
      }
    },
    minimal_participants: {
      get: function() {return this.surveyDetails.minimal_participants},
      set: function (modifiedValue) {
        this.updateData['minimal_participants'] = parseInt(modifiedValue);
      }
    },
    shuffle_items: {
      get: function() {return this.surveyDetails.shuffle_items},
      set: function (modifiedValue) {
        this.updateData['shuffle_items'] = modifiedValue;
      }
    }
  },

  methods: {
    ...mapActions([
      //'fetchSurveyDetail', replaced by fetchItem
      // 'fetchDivReturnRates', - replaced by fetchItems
      // 'updateSurvey' - replaced by saveItem
      'fetchItem',
      'fetchItems',
      'saveItem',
      'downloadItem'
    ]),

    downloadCsv () {
      let payload = {
        endpoint: ApiDownloadInstanceCsv + '/' + this.slug,
        slug: this.slug
      }
      this.downloadItem(payload)
    },

    toggleEdit(elem) {
      // Toggle the lock button next to the field. If it went from editable to non-editable,
      // use the field name to decide which data from updateData to send to the server and commit to store
      this.fieldEditable[elem] = !this.fieldEditable[elem];
      if (!this.fieldEditable[elem]) {
        let payload = {
          endpoint: ApiSurveys + '/' + this.slug,
          data: {[elem]: this.updateData[elem]}
        }
        this.$store.dispatch('saveItem', payload)
          .then(() => {
            // take the updated data out of the update cache so it doesn't get submitted again
            // TODO: deleting elem is supposed to be done within saveItem
            // method - check !!
            // delete this.updateData[elem]
          });
      }
    },
    r100() {
      return `${Math.floor(Math.random()*(90)+10)}%`;
    }
  },

  created () {
    let payload = {
      endpoint: ApiStats + '/surveySessions/' + this.slug
    }
    // Fetch details of the item
    this.fetchItem(payload).then(data => {
      this.surveyDetails = data.data[0]
    })

    // Fetch all return rates for the item
    payload = ApiStats + '/divReturnRates/' + this.slug
    this.fetchItems(payload)
      .then(res => {
        // console.log('res????')
      })
      .catch(error => {
        // this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: 'is-danger'
        })
        console.log('ERROR form submit: ', error)
      })


  }
}
</script>
